<template>
  <div>
     <!-- <payhead title="最近12个月历史水费记录"></payhead> -->
	 <div style="margin: 10px 16px 14px 16px;display: flex;text-align: center;justify-content: center;">
		 <div style="margin-right: auto;">
			 <van-icon name="cross" color="#6B83A6" size="24"  @click="jumpPayment" />
		 </div>
		 <div style="font-size: 17px;color: #000000;text-align: center;margin-right: auto;">近12月水费明细</div>
	 </div>
	 <div style="width: 100vw;height: 8px;background: #F5F5F5;"></div>

	 <div style="background: #FFFFFF;border: 1px solid #EEEEEE;padding: 16px;">
		 <div style="color: #0069FF;">{{waterCode}} | {{userName}} | {{address}}</div>
	 </div>

	 <div style="width: 100vw;height: 8px;background: #F5F5F5;"></div>

	 <div style="background: #FFFFFF;border-top: 1px solid #EEEEEE;padding-bottom: 16px;padding-top: 16px;">
		 <div v-for="itme in list" style="margin: 0 16px 16px 16px;background: #F2F7FF;border-radius: 4px;padding: 12px;">
			 <div style="color: #333333;margin-bottom: 8px;">{{itme.cmym}}<span style="color: #333333;margin-left: 20px;">抄表时间：{{itme.cbsj}}</span></div>
			 <div style="background: #DDDDDD;height: 1px;margin-bottom: 8px;"></div>
			 <van-row type="flex" justify="left">
			   <van-col span="12"><span>上月抄码：</span>{{itme.dyqm}}</van-col>
			   <van-col span="12"><span>当月抄码：</span>{{itme.dycm}}</van-col>
			 </van-row>
			 <van-row type="flex" justify="left">
			   <van-col span="12" style="margin-top: 8px;"><span>当月用水：</span>{{itme.sstw}} 吨</van-col>
			   <van-col span="12" style="margin-top: 8px;"><span>当月水费：</span>{{itme.qfje}} 元</van-col>
			 </van-row>
		 </div>

	 </div>

     <div>

       <!-- <van-form @submit="onSubmit"> -->
         <!-- 允许输入正整数，调起纯数字键盘 -->
         <!-- <van-search
             v-model="waterCode"
             shape="round"
             background="#4fc08d"
             type="digit"
             show-action
             @search="onSearch"
             placeholder="请输入缴费户号8位或12位数字"
         >
           <template #action>
             <div @click="onSearch">搜索</div>
           </template>
         </van-search>
       </van-form> -->
       <!-- <div class="interval"></div> -->
     </div>
    <!-- <div v-for="itme in list">
      <van-row type="flex" justify="center">
        <van-col span="22">{{itme.cmym}}</van-col>
      </van-row>
     <div class="card">
       <div class="intervalcard"></div>
       <van-row type="flex" justify="center">
         <van-col span="22">{{itme.sbbh}} | {{itme.yhmc}} | {{itme.yhdz}}</van-col>
       </van-row>
       <div class="intervalcard"></div>
      <van-row type="flex" justify="center">
        <van-col span="10"><span>上月抄码:</span>{{itme.dycm}}</van-col>
        <van-col span="10"><span>当月抄码:</span>{{itme.dyqm}}</van-col>
      </van-row>
      <div class="intervalcard"></div>
      <van-row type="flex" justify="center">
        <van-col span="10"><span>当月用水:</span>{{itme.sstw}} 吨</van-col>
        <van-col span="10"><span>当月水费:</span>{{itme.qfje}} 元</van-col>
      </van-row>
       <div class="intervalcard"></div>
     </div>
      <div class="intervalcards"></div>
    </div> -->
    <!-- <div class="connect" @click="jumpPayment">我要去缴费</div> -->
  </div>
</template>

<script>
import payhead from '../components/payhead'
import { mapState } from 'vuex'
import request from "@/api/index.js";
export default {
  name: '',
  components: {
    payhead
  },
  props: {},
  data() {
    return {
      token:'',
      waterCode:'',
	    userName: '',
	    address: '',
      from:{
        waterCode:'',
      },
      list:[]
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
    let da= this.$route.query.waterCode
	  let daN= this.$route.query.userName
	  this.token= this.$route.query.token
    if (da){
      this.waterCode=da
      this.userName=daN
      this.onSearch()
    }
  },
  mounted() {

  },
  methods: {
    onSearch(value){
      request.get('/pay/waterFee/history',{params:{waterCode:this.waterCode,token:this.token}}).then(res=>{
        this.list=res.data
        if(this.list.length > 0){
          this.address = this.list[0].yhdz
        }
      })
    },
    onSubmit(values){
      console.log('submit', this.from);
    },
    jumpPayment(){
      this.$router.push({path: '/', query: {waterCode:this.waterCode}})
    }
  },
}
</script>
<style lang='scss' scoped>
.interval{
  height:10px;
  width:100%;
}
.intervalcard{
  height:5px;
  width:100%;
}
.intervalcards{
  height:20px;
  width:100%;
}
span{
  color: #999;
}
.card{
  background-color: white;
  border-radius:10px;
}
.connect{
  text-align: center;
  margin-top:30px;
  color: blue;
  padding-bottom:30px;
}
.back{
  width: 50px;
  height:54px;
  float: left;
  color:#ffffff;
  background-color:#4fc08d;
}
</style>
